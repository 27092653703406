<template>
    <div class="allUpload">
        <div class="search_container searchArea">
            <el-row>
                <el-col :span="12">
                    <el-upload class="upload-demo" drag action
                            :multiple="false"
                            :show-file-list="false"
                            accept="csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            :http-request="httpRequest">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传excel文件，自动识别数据格式</div>
                    </el-upload>
                </el-col>
                <el-col :span="8" :offset="4">
                    <el-row>
                        <el-col :span="24">
                            <el-link :href="downloadUrl+'/hardclass_demo.xlsx'" download="行政班示例.xlsx" type="primary">行政班示例下载</el-link>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px">
                        <el-col :span="24">
                            <el-link :href="downloadUrl+'/student_demo.xlsx'" download="学生示例.xlsx" type="primary">学生示例下载</el-link>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px">
                        <el-col :span="24">
                            <el-link :href="downloadUrl+'/teacher_demo.xlsx'" download="教师示例.xlsx" type="primary">教师示例下载</el-link>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px">
                        <el-col :span="24">
                            <el-link :href="downloadUrl+'/course_demo.xlsx'" download="课程示例.xlsx" type="primary">课程示例下载</el-link>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px">
                        <el-col :span="24">
                            <el-link :href="downloadUrl+'/teaching_demo.xlsx'" download="教学班示例.xlsx" type="primary">教学班示例下载</el-link>
                        </el-col>
                    </el-row>

                </el-col>
            </el-row>


        </div>
        <div class="table_container" v-if="col && col.length > 0">
            <el-table v-loading="loading" :data="tableData" :row-style="uploadRowStyle" style="width: 100%" align='center'>

                <el-table-column :prop="item.prop" :label="item.label"  v-for="item in col"  :formatter="formatterStatus" align='center' ></el-table-column>

            </el-table>

        </div>
        <el-row>
            <el-col :span="8" :offset="8">
                <el-button type="primary" :loading="uploading"  v-if="col && col.length > 0" :disabled="!isCanUpload" @click="submitUpload">确定上传</el-button>
            </el-col>
        </el-row>

    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import * as simpleApi from "request/simple";
    import XLSX from "xlsx";  //引入xlsx
    export default {
        name: 'allUpload',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:false, uploading:false,
                downloadUrl: `https://www.test.com`,//https://www.51obe.com/public`, //这里直接去掉,暂时认为不用.
                selAll: null, selAllDtl: null,
                overWrite: false, isCanUpload: false, moduleName: null,
                col: [],
                hardClassCol: [{prop: 'name', label: '行政班名称', colName: '行政班名称', check: true }, {prop: 'year', label: '入学年', colName: '年级', check: true},
                    {prop: 'schoolName', label: '所属单位', colName: '所属单位', check: true }, {prop: 'professionName', label: '专业', colName: '专业', check: true, },
                    {prop: 'status', label: '状态', colName: null, }],
                studentCol: [{prop: 'name', label: '姓名', colName: '姓名', check: true, }, {prop: 'myNo', label: '学号', colName: '学号', check: true, },
                    {prop: 'hardClassName', label: '行政班名称', colName: '行政班名称', check: true, }, {prop: 'schoolName', label: '单位', colName: '单位', check: true, },
                    {prop: 'year', label: '入学年', colName: '入学年', check: true, }, {prop: 'professionName', label: '专业', colName: '专业', check: true, },
                    {prop: 'phoneNo', label: '手机号', colName: '手机号（选填）', },
                    {prop: 'status', label: '状态', colName: null, }],
                teacherCol: [{prop: 'jobNo', label: '工号', colName: '工号', check: true, },{prop: 'name', label: '姓名', colName: '教师姓名', check: true, },
                    {prop: 'phoneNo', label: '教师电话', colName: '教师电话（选填）', },
                    {prop: 'pass', label: '初始密码', colName: '初始密码（选填）', },
                     {prop: 'schoolName', label: '单位', colName: '单位', check: true, },{prop: 'subject', label: '学科', colName: '学科（选填）', },
                    {prop: 'status', label: '状态', colName: null, }],
                courseCol: [{prop: 'courseNo', label: '课程编号', colName: '课程编号', check: true },{prop: 'name', label: '课程名称', colName: '课程名称', check: true },
                    {prop: 'schoolName', label: '单位', colName: '单位', check: true }, {prop: 'subject', label: '学科', colName: '学科（选填）', },
                    {prop: 'nature', label: '课程性质', colName: '课程性质（选填）', },
                    {prop: 'status', label: '状态', colName: null, }],
                teachingCol: [{prop: 'name', label: '教学班名称', colName: '教学班名称', check: true }, {prop: 'acadyearTerm', label: '学年学期', colName: '学年学期', check: true},
                    {prop: 'courseName', label: '课程名称', colName: '课程名称', check: true, },
                    {prop: 'teacherName', label: '教师', colName: '教师', check: true }, {prop: 'teacher02Name', label: '助教', colName: '助教(选填)' },
                    {prop: 'status', label: '状态', colName: null, }],
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, schoolId: null, professionId: null},
            }
        },
        components:{

        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.downloadUrl = window.g.DEMO_URL;
	   },
        methods: {
            formatterStatus(row, column, cellValue, index) {
                if(column.property == 'status'){
                    if(cellValue == '00') return '覆盖';
                    if(cellValue == '01') return '新增';
                    if(cellValue == '02') return '检查中';
                    if(cellValue == '03') return '重复';
                    if(cellValue == '10') return '上传成功';
                    if(cellValue == '11') return '上传失败';
                    if(cellValue == '98') return '必填值为空';
                    if(cellValue == '99') return '无法上传';

                }
                if(column.property == 'schoolName' && "schoolStatus" in row){
                    let myStatus = row["schoolStatus"];
                    if(myStatus == '01') return cellValue+'(新增)';
                }
                if(column.property == 'professionName' && "professionStatus" in row){
                    let myStatus = row["professionStatus"];
                    if(myStatus == '01') return cellValue+'(新增)';
                }
                if(column.property == 'courseName' && "courseStatus" in row){
                    let myStatus = row["courseStatus"];
                    if(myStatus == '01') return cellValue+'(新增)';
                }
                if(column.property == 'teacherName' && "teacherStatus" in row){
                    let myStatus = row["teacherStatus"];
                    if(myStatus == '01') return cellValue+'(新增)';
                }
                if(column.property == 'teacher02Name' && "teacher02Status" in row){
                    let myStatus = row["teacher02Status"];
                    if(myStatus == '01') return cellValue+'(新增)';
                }

                return cellValue;

            },
            uploadRowStyle({row, rowIndex}){
                if(row.status == '99' || row.status == '98' || row.status == '03' || row.status == '11'){
                    console.log(rowIndex, row);
                    return {color: 'red' };
                }
                if(row.status == '00'){
                    console.log(rowIndex, row);
                    return {color: 'blue' };
                }
            },
            httpRequest(e) {
                let file = e.file; // 文件信息
                if (!file) {
                    // 没有文件
                    return false;
                } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
                    // 格式根据自己需求定义
                    this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
                    return false;
                }

                const fileReader = new FileReader();
                let this_ = this;
                fileReader.onload = ev => {
                    try {
                        const data = ev.target.result;
                        const workbook = XLSX.read(data, {
                            type: "binary" // 以字符编码的方式解析
                        });
                        console.log(workbook)
                        const exlname = workbook.SheetNames[0]; // 取第一张表
                        let exlData = XLSX.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
                        console.log(exlData);
                        if(!exlData || exlData.length == 0){
                            this.$message.error("数据格式错误");
                            return false;
                        }
                        if(this.hardClassFound(exlData)){
                            this.isCanUpload = true;
                            this.moduleName = 'hardClass';
                            this.postChecking('hardClass');
                            return ;
                        }
                        if(this.studentFound(exlData)) {
                            this.isCanUpload = true;
                            this.moduleName = 'student';
                            this.postChecking('student');
                            return ;
                        }
                        if(this.teacherFound(exlData)) {
                            this.isCanUpload = true;
                            this.moduleName = 'teacher';
                            this.postChecking('teacher');
                            return ;
                        }
                        if(this.courseFound(exlData)) {
                            this.isCanUpload = true;
                            this.moduleName = 'course';
                            this.postChecking('course');
                            return ;
                        }
                        if(this.teachingFound(exlData)) {
                            this.isCanUpload = true;
                            this.moduleName = 'teaching';
                            this.postChecking('teaching');
                            return ;
                        }

                        this.$message.error("数据格式错误");
                        return false;

                    } catch (e) {
                        console.log(e);
                        console.log("出错了：：", e);
                        return false;
                    }
                };
                fileReader.readAsBinaryString(file);
            },
            hardClassFound(exlData){
                let first = exlData[0];
                if('行政班名称' in first && '所属单位' in first && '专业' in first && '年级' in first){
                    this.readAndSetData(exlData, this.hardClassCol);
                    return true;
                }
                return false;
            },
            studentFound(exlData){
                let first = exlData[0];
                if('姓名' in first && '学号' in first && '行政班名称' in first && '单位' in first){
                    this.readAndSetData(exlData, this.studentCol);
                    return true;
                }
                return false;
            },
            teacherFound(exlData){
                let first = exlData[0];
                console.log('teacher', first);
                if('工号' in first && '教师姓名' in first){
                    this.readAndSetData(exlData, this.teacherCol);
                    return true;
                }
                return false;
            },
            courseFound(exlData){
                let first = exlData[0];
                if('课程编号' in first && '课程名称' in first ){
                    this.readAndSetData(exlData, this.courseCol);
                    return true;
                }
                return false;
            },
            teachingFound(exlData){
                let first = exlData[0];
                if('教学班名称' in first && '学年学期' in first ){
                    this.readAndSetData(exlData, this.teachingCol);
                    return true;
                }
                return false;
            },
            readAndSetData(exlData, columns){
                let tableData = [];
                this.col = columns;
                for(let i=0; i<exlData.length;i++){
                    let d = exlData[i];
                    let p = { status: '02' }
                    for(let j=0; j<columns.length; j++){
                        let col = columns[j];
                        if(col.colName != null && col.colName in d) {
                            let v = String(d[col.colName]);
                            if("check" in col && col.check && (v==null || v=='')) p.status = '98';
                            p[col.prop] = v
                        }
                    }
                    tableData.push(p);
                }
                console.log('readAndSetData', tableData)
                this.tableData = tableData;
            },
            postChecking(moduleName){
                this.isCanUpload = false;
                let p = { licenceId: this.userInfo.licenceId, moduleName: moduleName, data: this.tableData };
                let param = { controllerName: 'uploadCheck', methodName: '/all', param: p};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    console.log('postChecking', code);
                    if (code == 0) {
                        this.tableData =data;
                        for(let i=0; i<data.length; i++){
                            let d = data[i];
                            if(d.status == '99'){
                                this.isCanUpload = false;
                                return;
                            }
                        }
                        this.isCanUpload = true;
                    }

                }).catch((error) => {
                    this.isCanUpload = false;
                });
            },
            submitUpload(){

                let tableData = [];
                let isUpload = false;
                for(let i=0; i<this.tableData.length;i++){
                    let d = this.tableData[i];
                    if(d.status == '99' || d.status == '98' || d.status == '11'){
                        continue;
                    }
                    tableData.push(d);
                    isUpload = true;
                }
                if(!isUpload){
                    this.$message.error("无效数据，请重新整改再上传");
                    return false;
                }
                this.uploading = true;
                let p = { licenceId: this.userInfo.licenceId, moduleName: this.moduleName, data: tableData };
                let param = { controllerName: 'upload', methodName: '/all', param: p};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    console.log('postChecking', code, data);
                    if (code == 0) {
                        let successCount = 0;
                        let errorCount = 0;
                        for (let i=0; i<data.length; i++){
                            let d = data[i];
                            if(d.status == '10'){
                                successCount = successCount + 1;
                            }
                            if(d.status == '11'){
                                errorCount = errorCount + 1;
                            }
                        }
                        this.tableData =data;
                        this.$alert('上传成功：'+successCount + '条， 失败：'+errorCount + '条', '提醒');
                        this.uploading = false;
                    }

                }).catch((error) => {
                    this.uploading = false;
                });
            }
        },


    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


